import { TContinentCode, continents } from 'countries-list'

import { RegionCode } from './types'

/**
 * Returns countries-list continent(s) from a supported RegionCode.
 * @param region supportedRegion
 * @returns continent(s) that match the region
 */
export const getContinentsFromRegion = (region: RegionCode): TContinentCode | TContinentCode[] => {
  if (region === 'AM') {
    return ['NA', 'SA']
  }

  if (region === 'GLOBAL') {
    return Object.keys(continents) as TContinentCode[]
  }

  return region as TContinentCode
}
