import getCurrentDataset from './getCurrentDataset'
import getDatasets from './getDatasets'
import setCurrentDataset from './setCurrentDataset'
import setDatasets from './setDatasets'

export const datasets = {
  getDatasets,
  setDatasets,
  getCurrentDataset,
  setCurrentDataset
}
