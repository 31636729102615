import cache, { clear, del, get, put } from 'memory-cache'

/**
 * Checks if a storage type is available
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
 * @author MDN
 * @param type The type of the storage
 * @returns true if available, false if not
 */
function storageAvailable (type: 'localStorage' | 'sessionStorage') {
  let storage
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return e instanceof DOMException && (
    // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0)
  }
}

/**
 * A collection of different storage methods
 */
const storage = {
  /**
   * A shim for localStorage, uses memory cache when it's not available
   */
  localStorage: {
    getItem: (key: string): string | null => {
      if (storageAvailable('localStorage')) {
        return localStorage.getItem(key)
      } else {
        return get(key)
      }
    },
    setItem: (key: string, value: string) => {
      if (storageAvailable('localStorage')) {
        localStorage.setItem(key, value)
      } else {
        put(key, value)
      }
    },
    removeItem: (key: string) => {
      if (storageAvailable('localStorage')) {
        localStorage.removeItem(key)
      } else {
        del(key)
      }
    },
    clear: () => {
      if (storageAvailable('localStorage')) {
        localStorage.clear()
      } else {
        clear()
      }
    }
  },
  /**
   * A shim for sessionStorage, uses memory cache when it's not available
   */
  sessionStorage: {
    getItem: (key: string): string | null => {
      if (storageAvailable('sessionStorage')) {
        return sessionStorage.getItem(key)
      } else {
        return get(key)
      }
    },
    setItem: (key: string, value: string) => {
      if (storageAvailable('sessionStorage')) {
        sessionStorage.setItem(key, value)
      } else {
        put(key, value)
      }
    },
    removeItem: (key: string) => {
      if (storageAvailable('sessionStorage')) {
        sessionStorage.removeItem(key)
      } else {
        del(key)
      }
    },
    clear: () => {
      if (storageAvailable('sessionStorage')) {
        sessionStorage.clear()
      } else {
        clear()
      }
    }
  },
  /**
   * Memory cache, stores key/values in memory only for the current session
   */
  memoryCache: cache,
  storageAvailable
}

export default storage
