import { FAVOURITES_STORAGE_KEY } from '.'

import storage from '@/storage'

import { FavouriteVehicle } from './types'

export const getFavourites = (): FavouriteVehicle[] | null => {
  const favourites = storage.localStorage.getItem(FAVOURITES_STORAGE_KEY)

  if (favourites) {
    const currentFavourites: FavouriteVehicle[] = JSON.parse(favourites)
    return currentFavourites
  }

  return null
}
