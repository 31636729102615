import { Language } from './Language'

export default function replaceLanguageInPath (path: string, oldLanguage: Language, newLanguage: Language): string {
  const oldLanguageCode = oldLanguage.code2
  const newLanguageCode = newLanguage.code2

  if (oldLanguageCode === newLanguageCode) {
    return path
  }

  // Check if the path is the root path
  const isRoot =
    path === `/${oldLanguageCode}` ||
    path === `/${oldLanguageCode}/`

  // Create a regex to find the language in the path
  const regex = new RegExp('/' + oldLanguageCode + '\\b[/]?')

  // Create a replacement for the regex
  let replacement = '/' + newLanguageCode
  if (!isRoot) {
    // Add a trailing forward slash if the path is not the root path
    replacement += '/'
  }

  // Create the new path by replacing the old language with the selected language
  const newPath = path.replace(regex, replacement)

  return newPath
}
