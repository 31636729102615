import { debug } from 'debug'

const debugLoadScript = debug('root-app:gtm:loadScript')

export function loadScript (containerId: string) {
  debugLoadScript('Loading gtm script for container %s', containerId)
  window.dataLayer = window.dataLayer ?? []

  window.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime()
  })

  const queryString: URLSearchParams = new URLSearchParams({
    id: containerId
  })

  const doc: Document = document
  const script: HTMLScriptElement = doc.createElement('script')

  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?${queryString}`

  doc.body.appendChild(script)

  debugLoadScript('Loaded')
}
