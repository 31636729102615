// eslint-disable-next-line no-console
console.debug(`${APP_NAME}: v${APP_VERSION}`)

export { default as storage } from './storage'

export { siteConfigurationCache } from './cache/siteConfiguration'
export { siteDataConfigurationCache } from './cache/siteDataConfiguration'

export { getCustomerName } from './getCustomerName'

export { languages } from './languages'
export { datasets } from './datasets'
export { translations } from './translations'

export { generateErrorTemplate } from './exceptions/generateErrorTemplate'

export * from './gtm'
export type { Dataset } from './datasets/Dataset'
export type { Language } from './languages/Language'

export { default as ImageUtils } from './image/utils'

export { eventEmitter } from './eventEmitter'

export type { Country, CountryCode, CountryCodeUnknown, RegionCode, CountryExpanded, SupportedLicenseplateSearchRouteHashes } from './countries/types'
export { countries } from './countries'

export { favourites } from './favourites'
export type { FavouriteVehicle } from './favourites/types'
