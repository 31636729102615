import { debug } from 'debug'

import { SiteConfiguration } from '@olyslager/customer-configuration'

import storage from '../../storage'

const debugSetSiteConfiguration = debug('global-utilities:setSiteConfiguration')

const getSiteConfiguration = () : SiteConfiguration => {
  const siteConfiguration: SiteConfiguration = storage.memoryCache.get('siteConfiguration')
  return siteConfiguration
}

const setSiteConfiguration = (siteConfiguration: SiteConfiguration) => {
  if (!siteConfiguration) {
    throw new Error('siteConfiguration parameter is required')
  }

  debugSetSiteConfiguration('Set siteConfiguration')

  storage.memoryCache.put('siteConfiguration', siteConfiguration)
}

export const siteConfigurationCache = {
  get: getSiteConfiguration,
  set: setSiteConfiguration
}
