import { getCountryData } from 'countries-list'

import { countries } from './index'

import { getRegionFromContinent } from './getRegionFromContinent'
import { supportedVinCountries } from './supportedVinCountries'
import { supportedVrnCountries } from './supportedVrnCountries'
import { CountryCode, CountryExpanded } from './types'

/**
 * converts iso2 country code to our CountryExpanded object
 * @param countryCode iso2 country code
 * @returns CountryExpanded object
 */
export const getExpandedCountryData = (countryCode: CountryCode): CountryExpanded => {
  if (countryCode === countries.unknownCountryCode) {
    return countries.unknownCountry
  }

  const countryData = getCountryData(countryCode)

  let hasVRN = false
  let hasVin = false

  if (supportedVrnCountries.includes(countryData.iso2)) {
    hasVRN = true
  }

  if (supportedVinCountries.includes(countryData.iso2)) {
    hasVin = true
  }

  const countryExpanded: CountryExpanded = {
    iso2: countryData.iso2,
    name: countryData.name,
    continent: countryData.continent,
    region: getRegionFromContinent(countryData.continent),
    vrn: hasVRN,
    vin: hasVin
  }

  return countryExpanded
}
