export declare type Language = {
  code: string;
  code2: string;
  name: string;
  localName: string
}

export const defaultLanguage: Language = {
  code: 'eng',
  code2: 'en',
  name: 'English',
  localName: 'English'
}
