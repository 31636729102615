import { debug } from 'debug'

import getLanguages from './getLanguages'
import { Language } from './Language'
const debugGetLanguageByLocale = debug('global-utilities:getLanguageByLocale')

/**
 * Tries to find a Language by locale or an Olyslager 3-character code
 * @param locale A normal i18n locale or an Olyslager 3-character code
 * @param stripRegion If the region part of the locale will be removed before searching (i.e. 'en-US' becomes 'en')
 */
export default function getLanguageByLocale (locale: string, stripRegion?: boolean): Language | undefined {
  debugGetLanguageByLocale('Attempting to get language by locale %O', locale)
  if (!locale || (locale.length !== 2 && locale.length !== 3 && locale.length !== 5)) {
    // eslint-disable-next-line no-console
    console.warn('Invalid or missing locale', locale)
    return undefined
  }
  // Get all languages
  const languageList = getLanguages()
  debugGetLanguageByLocale('Languages: %O', languageList)

  // Strip the region from the locale
  if (stripRegion) {
    debugGetLanguageByLocale('Stripping region from locale')
    if (locale.length === 5 && locale.substr(2, 1) === '-') {
      locale = locale.slice(0, 3)
    }
    debugGetLanguageByLocale('Locale is now %O after stripping', locale)
  }

  // Find the language by locale
  const language = languageList.find(language =>
    language.code.toLowerCase() === locale.toLowerCase() ||
    language.code2.toLowerCase() === locale.toLowerCase()
  )

  debugGetLanguageByLocale('Language found: %O', language)

  // Return the found language or undefined
  return language
}
