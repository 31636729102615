/**
 * Generates a template which can be used to replace the main content in case of a fatal error.
 * When including a message, keep it generic to prevent leaking unintended data to the user.
 * @param error new Error() error object with or without message
 * @returns html string
 */
export function generateErrorTemplate (error: Error): string {
  return `
  <div style="display: flex; flex-direction: column; align-items: center; margin-top: 36vh;">
    <div style="padding: 2rem">
      <h1 style="margin-bottom: 1rem;">Something went wrong...</h1>
      <p style="margin-bottom: 1rem;">We encountered a problem which prevented us from loading your site, please try again</p>
      <p style="font-size: 0.8rem;"><code>Error: ${(error?.message) ? error.message : 'Unknown'}</code></p>
    </div>
  </div>
  `
}
