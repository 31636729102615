import mitt from 'mitt'

import { FavouriteVehicle } from '../favourites/types'

export type Events = {
  refreshFrontend: { frontendName: string }
  favouriteRemoved: { favourite: number }
  favouriteAdded: { favourite: FavouriteVehicle }
  favouriteSelected: { favourite: FavouriteVehicle }
  loginRequired
  loginSucceeded
}

export const eventEmitter = mitt<Events>()
