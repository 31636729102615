import { debug } from 'debug'

import storage from '../storage'

import { Language } from './Language'
const debugSetLanguages = debug('global-utilities:setLanguages')

export default function setLanguages (languages: Language[]) {
  if (!languages || languages.length === 0) {
    throw new Error('Languages parameter is required and should not be empty')
  }

  debugSetLanguages('Setting languages to %O', languages)

  storage.memoryCache.put('languages', languages)
}
