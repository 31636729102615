import getLanguages from './getLanguages'
import { Language } from './Language'

export default function getBrowserLanguage () : Language | undefined {
  // Get all languages
  const languageList = getLanguages()

  let language : Language | undefined
  // Find the first matching language
  for (const navigatorLanguage of navigator.languages) {
    if (!language) {
      // remove the location part from the locale, because we currently don't have those
      language = languageList.find(lang => lang.code2.toLowerCase() === navigatorLanguage.slice(0, 2).toLowerCase())
    }
  }

  return language
}
