export default class ImageUtils {
  File: File | undefined = undefined
  ArrayBuffer: ArrayBuffer | undefined = undefined
  DataUrl: string | undefined = undefined
  Image: HTMLImageElement | undefined = undefined
  ImageMaxWidth = 2048
  ImageMaxHeight = 2048
  _imageOrientation = -1

  async LoadImage (file) {
    this.File = file

    const arrayBuffer = _readFileAsArrayBuffer(file)
    const dataUrl = _readFileAsDataUrl(file)
    this.DataUrl = await dataUrl
    const image = _readDataUrlAsImage(this.DataUrl)
    this.Image = await image
    this.ArrayBuffer = await arrayBuffer

    if (this.ArrayBuffer !== undefined && this.DataUrl !== undefined && this.Image !== undefined) {
      return true
    } else {
      return false
    }
  }

  async IsResizeNeeded () {
    if (!this.Image) return false

    const width = this.Image.width
    const height = this.Image.height
    const shouldResize =
      width > this.ImageMaxWidth ||
      height > this.ImageMaxHeight

    return shouldResize
  }

  async ResizeImage () {
    if (!this.Image) return false
    if (!this.File) return false

    let newWidth, newHeight
    const width = this.Image.width
    const height = this.Image.height

    if (width > height) {
      newHeight = height * (this.ImageMaxWidth / width)
      newWidth = this.ImageMaxWidth
    } else {
      newWidth = width * (this.ImageMaxHeight / height)
      newHeight = this.ImageMaxHeight
    }

    const canvas = document.createElement('canvas')
    canvas.width = newWidth
    canvas.height = newHeight
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const context = canvas.getContext('2d')!
    context.drawImage(this.Image, 0, 0, newWidth, newHeight)

    this.DataUrl = canvas.toDataURL(this.File.type)
    const newImage = _readDataUrlAsImage(this.DataUrl)
    const newBlob = await _canvasToBlob(canvas, this.File.type)
    const arraybuffer = _readFileAsArrayBuffer(newBlob)
    this.Image = await newImage
    this.ArrayBuffer = await arraybuffer
  }
}

async function _readFileAsArrayBuffer (file): Promise<ArrayBuffer> {
  const reader = new FileReader()
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as ArrayBuffer)
    }
    reader.readAsArrayBuffer(file)
  })
}

async function _readFileAsDataUrl (file): Promise<string> {
  const reader = new FileReader()
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(file)
  })
}

async function _readDataUrlAsImage (dataUrl): Promise<HTMLImageElement> {
  const img = new Image()
  return new Promise((resolve) => {
    img.onload = () => {
      resolve(img)
    }
    img.src = dataUrl
  })
}

async function _canvasToBlob (canvas, fileType) {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob)
    }, fileType)
  })
}
