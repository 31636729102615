import { debug } from 'debug'

import storage from './storage'

const debugGetCustomerName = debug('global-utilities:getCustomerName')

export function getCustomerName () : string {
  debugGetCustomerName('Getting customer name')

  // read from url
  const url = window.location
  let customerName = url.hostname

  const isDebugUrl =
    customerName.endsWith('netlify.app') ||
    customerName.endsWith('bs-local.com') || // browserstack local testing url to test localhost on iOS/Mac
    customerName === 'localhost'

  if (isDebugUrl) {
    // only read from localStorage if on a debug url
    const nameFromLocaleStorage = storage.localStorage.getItem('customer-configuration/customer')
    if (nameFromLocaleStorage) {
      customerName = nameFromLocaleStorage
      debugGetCustomerName('We\'re on Netlify, so instead take from localStorage')
    } else {
      debugGetCustomerName('We\'re on Netlify, but localStorage isn\'t used')
    }
  }

  debugGetCustomerName('Customer name is "%s"', customerName)
  return customerName
}
