import { debug } from 'debug'

import getBrowserLanguage from './getBrowserLanguage'
import getDefaultLanguage from './getDefaultLanguage'
import { Language } from './Language'
const debugGetBrowserOrDefaultLanguage = debug('global-utilities:getBrowserOrDefaultLanguage')

export default function getBrowserOrDefaultLanguage (): Language {
  let language = getBrowserLanguage()
  debugGetBrowserOrDefaultLanguage('Browser language: %O', language)
  if (!language) {
    language = getDefaultLanguage()
    debugGetBrowserOrDefaultLanguage('No browser language found, getting default language: %O', language)
  }
  return language
}
