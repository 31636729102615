import storage from '../storage'

import { getExpandedCountryData } from './getExpandedCountryData'
import { CountryCode, CountryExpanded } from './types'

export const getCurrentVrnCountry = (): CountryExpanded | null => {
  const countryCode = storage.localStorage.getItem('user/current-vrn-country') as CountryCode

  if (countryCode === null) {
    return null
  }

  return getExpandedCountryData(countryCode)
}
