import { debug } from 'debug'

import storage from '../storage'

import { Language } from './Language'
const debugSetDefaultLanguage = debug('global-utilities:setDefaultLanguage')

export default function setDefaultLanguage (language: Language) {
  if (!language || !language.code || !language.code2 || !language.name || !language.localName) {
    throw new Error('Invalid or missing language object')
  }

  debugSetDefaultLanguage('Setting defaultLanguage to %O', language)

  storage.memoryCache.put('defaultLanguage', language)
}
