import { addFavourite } from './addFavourite'
import { getFavourites } from './getFavourites'
import { removeFavourite } from './removeFavourite'

export const FAVOURITES_STORAGE_KEY = 'favourites'
export const MAX_FAVOURITES = 10

export const favourites = {
  getFavourites,
  addFavourite,
  removeFavourite,
  MAX_FAVOURITES
}
