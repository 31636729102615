import { getContinentsFromRegion } from './getContinentFromRegion'
import { getCurrentCountry } from './getCurrentCountry'
import { getCurrentVrnCountry } from './getCurrentVrnCountry'
import { getDefaultCountry } from './getDefaultCountry'
import { getExpandedCountryData } from './getExpandedCountryData'
import { getRegionFromContinent } from './getRegionFromContinent'
import { setCurrentCountry } from './setCurrentCountry'
import { setCurrentVrnCountry } from './setCurrentVrnCountry'
import { setDefaultCountry } from './setDefaultCountry'
import { supportedLicensePlateSearchCountries } from './supportedLicensePlateSearchCountries'
import { supportedTruckCountries } from './supportedTruckCountries'
import { supportedVinCountries } from './supportedVinCountries'
import { supportedVrnCountries } from './supportedVrnCountries'
import { CountryCodeUnknown, CountryExpanded } from './types'
import { vrnCategoryMap } from './vrnCategoryMap'

export const countries = {
  vrnCategoryMap,
  supportedLicensePlateSearchCountries,
  supportedVinCountries,
  supportedVrnCountries,
  supportedTruckCountries,
  getCurrentCountry,
  setCurrentCountry,
  getCurrentVrnCountry,
  setCurrentVrnCountry,
  getDefaultCountry,
  setDefaultCountry,
  getExpandedCountryData,
  getContinentsFromRegion,
  getRegionFromContinent,
  unknownCountryCode: 'XX' as CountryCodeUnknown,
  unknownCountry: {
    iso2: 'XX',
    name: 'World',
    continent: 'AN',
    region: 'GLOBAL',
    vrn: false,
    vin: false
  } as CountryExpanded & {
    iso2: CountryCodeUnknown
  }
}
