import { debug } from 'debug'

import storage from '../storage'

import { Dataset } from './Dataset'

const debugSetCurrentDataset = debug('global-utilities:setCurrentDataset')

export default function setCurrentDataset (dataset: Dataset) {
  if (!dataset || !dataset.code) {
    throw new Error('Invalid or missing dataset object')
  }

  debugSetCurrentDataset('Setting current dataset to %O', dataset)

  storage.memoryCache.put('currentDataset', dataset)
}
