import { debug } from 'debug'

import { loadScript } from './loadScript'

const debugLoadGtm = debug('root-app:gtm:loadGtm')

export function loadGtm (containerIds: string[] | undefined) {
  if (!containerIds || containerIds.length === 0) {
    // eslint-disable-next-line no-console
    console.error('No gtm container ids found!')
    return
  }
  debugLoadGtm('Loading gtm with container ids %O', containerIds)
  containerIds.forEach(containerId => {
    loadScript(containerId)
  })
}
