import { CountryCode } from './types'

export const supportedVrnCountries: CountryCode[] = [
  'DK', // Denmark
  'FI', // Finland
  'DE', // Germany
  'FR', // France
  'IE', // Ireland
  'IT', // Italy
  'NL', // Netherlands
  'NO', // Norway
  'PT', // Portugal
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // United Kingdom
  'US', // United States of America
  'AT' // Austria
]
