import storage from '../storage'

import { CountryCode } from './types'

/**
 * stores string of country (iso2) in localstorage
 * @param countryCode iso2 code of country
 */
export const setCurrentCountry = (countryCode: CountryCode) => {
  storage.localStorage.setItem('user/current-country', countryCode)
}
