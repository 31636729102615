import { TContinentCode } from 'countries-list'

import { RegionCode } from './types'

/**
 * Returns a supported RegionCode from provided continent
 * @param continent a countries-list continent
 * @returns supported RegionCode
 */
export const getRegionFromContinent = (continent: TContinentCode): RegionCode => {
  const supportedRegions: RegionCode[] = ['AF', 'AS', 'EU', 'AM', 'OC', 'GLOBAL']
  let region: RegionCode = 'GLOBAL'

  if (continent === 'NA' || continent === 'SA') {
    region = 'AM'
  }

  if (supportedRegions.includes(continent as RegionCode)) {
    region = continent as RegionCode
  }

  return region
}
