import { CountryCode } from './types'

// categories:
// 1: cars
// 2: vans
// 3: trucks
// 4: moto

/**
 * Maps which categories can be searched in per country for licenseplates, where categories can be toggled
 */
export const vrnCategoryMap = new Map<CountryCode, number[]>([
  ['FR', [1, 2, 3]],
  ['GB', [1, 2, 3, 4]]
])
