import { debug } from 'debug'

import { TranslationDictionary } from '@olyslager/customer-configuration'

import storage from '../storage'
const debugSetLanguages = debug('global-utilities:setTranslations')

export default function setTranslations (translations: TranslationDictionary) {
  if (!translations || Object.keys(translations).length === 0) {
    throw new Error('Translations parameter is required and should not be empty')
  }

  debugSetLanguages('Setting translations to %O', translations)

  storage.memoryCache.put('translations', translations)
}
