import { countries } from './index'

import storage from '../storage'

import { getExpandedCountryData } from './getExpandedCountryData'
import { CountryCode, CountryExpanded } from './types'

export const getCurrentCountry = (): CountryExpanded | null => {
  const countryCode = storage.localStorage.getItem('user/current-country') as CountryCode

  if (countryCode === null || countryCode === countries.unknownCountryCode) {
    return null
  }

  return getExpandedCountryData(countryCode)
}
