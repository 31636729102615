import { debug } from 'debug'

import storage from '../storage'

import { Dataset } from './Dataset'

const debugSetDatasets = debug('global-utilities:setDatasets')

export default function setDatasets (datasets: Dataset[]) {
  if (!datasets || datasets.length === 0) {
    throw new Error('Datasets parameter is required and should not be empty')
  }

  debugSetDatasets('Setting datasets to %O', datasets)

  storage.memoryCache.put('datasets', datasets)
}
