import { debug } from 'debug'

import { SiteDataConfiguration } from '@olyslager/customer-configuration'

import storage from '../../storage'

const debugSetSiteDataConfiguration = debug('global-utilities:setSiteDataConfiguration')

const getSiteDataConfiguration = () : SiteDataConfiguration => {
  const siteDataConfiguration: SiteDataConfiguration = storage.memoryCache.get('siteDataConfiguration')
  return siteDataConfiguration
}

const setSiteDataConfiguration = (siteDataConfiguration: SiteDataConfiguration) => {
  if (!siteDataConfiguration) {
    throw new Error('siteDataConfiguration parameter is required')
  }

  debugSetSiteDataConfiguration('Set siteDataConfiguration')

  storage.memoryCache.put('siteDataConfiguration', siteDataConfiguration)
}

export const siteDataConfigurationCache = {
  get: getSiteDataConfiguration,
  set: setSiteDataConfiguration
}
