import { FAVOURITES_STORAGE_KEY } from '.'

import storage from '@/storage'

import { getFavourites } from './getFavourites'

export const removeFavourite = (typeId: number) => {
  const favourites = getFavourites()

  if (favourites) {
    const newFavourites = favourites.filter(f => f.typeId !== typeId)
    storage.localStorage.setItem(FAVOURITES_STORAGE_KEY, JSON.stringify(newFavourites))
  }
}
