import getBrowserLanguage from './getBrowserLanguage'
import getBrowserOrDefaultLanguage from './getBrowserOrDefaultLanguage'
import getCurrentLanguage from './getCurrentLanguage'
import getDefaultLanguage from './getDefaultLanguage'
import getLanguageByLocale from './getLanguageByLocale'
import getLanguages from './getLanguages'
import replaceLanguageInPath from './replaceLanguageInPath'
import setCurrentLanguage from './setCurrentLanguage'
import setDefaultLanguage from './setDefaultLanguage'
import setLanguages from './setLanguages'

export const languages = {
  getLanguages,
  setLanguages,
  getDefaultLanguage,
  setDefaultLanguage,
  getCurrentLanguage,
  setCurrentLanguage,
  getBrowserLanguage,
  getBrowserOrDefaultLanguage,
  getLanguageByLocale,
  replaceLanguageInPath
}
