import { debug } from 'debug'

import storage from '../storage'

import { Language } from './Language'
const debugSetCurrentLanguage = debug('global-utilities:setCurrentLanguage')

export default function setCurrentLanguage (language: Language) {
  if (!language || !language.code || !language.code2 || !language.name || !language.localName) {
    throw new Error('Invalid or missing language object')
  }

  debugSetCurrentLanguage('Setting currentLanguage to %O', language)

  storage.memoryCache.put('currentLanguage', language)
}
