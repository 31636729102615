import { FAVOURITES_STORAGE_KEY, MAX_FAVOURITES } from '.'

import storage from '@/storage'

import { getFavourites } from './getFavourites'
import { FavouriteVehicle } from './types'

export const addFavourite = (favourite: FavouriteVehicle) => {
  const favourites = getFavourites()

  if (!favourites) {
    storage.localStorage.setItem(FAVOURITES_STORAGE_KEY, JSON.stringify([favourite]))
    return
  }

  if (favourites.length < MAX_FAVOURITES) {
    favourites.push(favourite)
    storage.localStorage.setItem(FAVOURITES_STORAGE_KEY, JSON.stringify(favourites))
  }
}
